<template>
  <footer class="footer text-secondary text-center">
    <p class="fs-14 mb-24 fw-3 line1">Due to the confidentiality of company projects, feel free to contact me for more details.</p>
    <p class="fs-16 mb-0 line2">Copyright © 2024 Designed by Lei Ke (dev help from <a class="text-primary" href="https://www.linkedin.com/in/tian-chen-233037326/" target="_blank">Tian Chen</a>)</p>
  </footer>
</template>
    
  <script>
export default {
  name: "PageFooter",
  data() {
    return {}
  },
};
</script>
<style scoped lang="scss">
  .footer {
    padding: 48px 24px;
    p {
      padding: 0 !important;
      margin-top: 0 !important;
    }
    .line1 {
      color: #fff6;
    }
    .line2 {
      color: #fff9;
    }
  }
</style>