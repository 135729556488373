<template>
  <div class="page-header full-width d-flex justify-content-between align-items-center">
    <router-link to="/" class="text-decoration-none text-white logo"><span class="d-flex justify-content-center align-items-center fs-3"><img loading="lazy" class="mr-4" :src="hashUrl('logo.svg')"/><b>Lei Ke</b></span></router-link>
    <ul class="d-flex links list-style-none p-0 ml-20">
      <li
        v-for="(item, index) in HEADER_LINKS"
        :key="index"
        class="d-inline-flex"
      >
        <a :href="item.link" v-if="item.isOutLink" target="_blank" class="d-inline-flex align-items-center text-decoration-none fs-20 hover-link fw-5">
          <span class="link-text">{{ item.name }}</span>
          <img loading="lazy" class="link-img" :src="item.img" />
          <img loading="lazy" class="link-icon" v-if="item.icon" :src="item.icon" />
        </a>
        <router-link
          v-else
          :to="item.link"
          class="d-inline-flex align-items-center text-decoration-none fs-20 hover-link fw-5"
          >
          <span class="link-text">{{ item.name }}</span>
          <img loading="lazy" class="link-img" :src="item.img" />
          <img loading="lazy" class="link-icon" v-if="item.icon" :src="item.icon" /></router-link
        >
      </li>
    </ul>
  </div>
</template>
  
<script>
import { HEADER_LINKS } from "@/constant";
export default {
  name: "PageHeader",
  data() {
    return {
      HEADER_LINKS,
    };
  }
};
</script>
  