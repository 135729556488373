<template>
  <div id="app" class="full-width">
    <page-header></page-header>
    <router-view/>
    <page-footer></page-footer>
  </div>
</template>

<script>
import pageHeader from './components/page-header.vue'
import pageFooter from './components/page-footer.vue';
export default {
  name: 'App',
  components: {
    pageHeader,
    pageFooter
  }
}
</script>

<style lang="scss">
@import "style/app.scss"
</style>
