import hash from 'hash.js';
export const hashUrl = (path) => {
    const date = new Date();
    const dateString = date.toISOString().split('T')[0];
    const hashCode = hash.sha256().update(dateString).digest('hex');
    return `https://thet92.github.io/lei_site_images/${path}?v=${hashCode}`;
}

export const HEADER_LINKS = [
    { name: 'About', img: hashUrl('about.svg'), link: '/about' },
    // { link: '/mail', icon: 'mail.svg' },
    { name: 'Linkedin', img: hashUrl('Linkedin.svg'), isOutLink: true, link: 'https://www.linkedin.com/in/lei-ke-057b3bbb/', icon: hashUrl('arrow.svg') },
    { name: 'CV', img: hashUrl('cv.svg'), isOutLink: true, link: 'https://drive.google.com/file/d/1TVC6ZVUhRZI2XDNCc2v5aFY8ge3TjZHZ/view?usp=sharing', icon: hashUrl('arrow.svg') },
]

export const MY_PROJECTS = [
    { name: 'Mi Office', cover: hashUrl('1.png'), logo: hashUrl('mi_office.png'), link: '/project/miOffice', desc: 'APP Design | Xiaomi' },
    { name: 'Mi Travel', cover: hashUrl('2.png'), logo: hashUrl('mi_travel.png'), link: '/project/miTravel', desc: 'APP Design | Xiaomi' },
    { name: 'Mi After-sale', cover: hashUrl('3.png'), logo: hashUrl('mi.png'), link: '/project/afterSale', desc: 'APP Design | Xiaomi' },
    { name: 'HIUI', cover: hashUrl('4.png'), logo: hashUrl('HIUIPRO.png'), link: '/project/hiui', desc: 'Design System | Xiaomi' },
    { name: 'Mi Cost Estimate', cover: hashUrl('5.png'), logo: hashUrl('mi_cost.png'), link: '/project/miCost', desc: 'Desk Platform Design | Xiaomi' },
    { name: 'Other Design', coverVideo: hashUrl('video1.mp4'), logo: hashUrl('design.png'), link: '/project/graphicDesign', desc: 'Recent Interesting Designs' },
]

export const ABOUT_CONTENTS = [
    {
        title: "Why design?",
        paragraphs: ['From a little girl who loved to draw to a designer creating one interface after another with the help of technology, I take immense pride in using my skills to solve user problems and create value for clients. Becoming a designer is one of the greatest blessings in my life. For me, design is more than just a job—it’s a bridge that connects me to the world.']
    },
    {
        title: "My jounery",
        paragraphs: [
            `<b>Before 2023</b>, I worked for over four years as a UX designer at Xiaomi, one of the youngest Fortune 500 companies. I was proud to grow alongside the company in the IT department’s User Experience Design team. Early on, I contributed to the design of Xiaomi’s e-commerce customer service system and led the UX/UI design of the after-sales management App. Later, I focused on smart office systems, successfully leading the design of over 10 key projects, including enterprise approval systems and cost estimation management tools.`,
            { type: 'img', props: { loading: "lazy" }, class: "w-100 mb-36", url: hashUrl('about/img1.png') },
            'I am deeply grateful to my previous company and all my colleagues at Xiaomi. Working alongside some of the best talents in the industry, I learned so much and enhanced my skills across various areas—from user research and UI/UX design to hand-drawing, 3D modeling, and motion design. I have continuously improved throughout my journey.',
            { type: 'img', props: { loading: "lazy" }, class: "w-100 mb-36", url: hashUrl('about/img2.png') },
            `<b>In 2022</b>, I made a bold decision to study abroad in Ireland. Reflecting on the value of life during the 2019 pandemic, I realized that as a designer, I shouldn’t remain in my comfort zone. After six months of language classes, I successfully enrolled in Dublin City University for a Master’s in Emerging Media. During my studies, I learned extensively and leveraged my work experience to lead my classmates in completing various projects. I also designed and developed the official website for the “<a class="text-primary underline" href="https://www.vijanatwawezaclub.org/" target="_blank">Vijanatwawezaclub</a>”, a refugee camp in Africa, to help them raise more funds. My year in Ireland has been incredibly rewarding.`,
            { type: 'img', props: { loading: "lazy" }, class: "w-100 mb-36", url: hashUrl('about/img3.png') },
            { type: 'img', props: { loading: "lazy" }, class: "w-100", url: hashUrl('about/img4.png') },
        ]
    },
    {
        title: "About the future",
        paragraphs: [
            `<b>In July 2024</b>, I completed all my Master’s courses and am eager to return to the workforce as a UX designer. Compared to the person I was over a year ago, my perspective has broadened significantly. I translated and redesigned my past projects into English and created my personal website.`,
            `Thank you to all viewers,<br/>let’s look forward to new beginnings together.`,
            `<b>Last updated by Lei Ke on Oct , 2024</b>`
        ]
    },
]

export const PROJECTS = {
    "miOffice": [
        {
            tag: 'h1',
            props: { class: 'project-name fs-48' },
            text: 'Mi Office'
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Company'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'Xiao Mi'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Role'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'UX/UI designer'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap mb-120' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Duration'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: '2020-2022'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Deliverable'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: 'UI&UX Design, Graphic Design'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'What is Mi Office?'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Mi Office is an intelligent office platform built on Lark’s native system, tailored specifically for Xiaomi. As the design team within the IT department, we have created over 60 mobile and PC-based mini-apps to address various business needs, including HR management, workflow approvals, financial management, etc.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-20', src: hashUrl('mioffice/img1.png') },
        },
        {
            tag: 'div',
            props: { class: 'w-100 overflow-hidden clear-float mb-90' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('mioffice/img2.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('mioffice/img3.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('mioffice/img4.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left mr-0', src: hashUrl('mioffice/img4a.png') },
                },
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'About our users'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Our users include all internal employees and external collaborators.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mioffice/img17.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Business Goal'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap mb-90' },
            children: [
                {
                    tag: 'span',
                    text: 'To help all internal employees and partners efficiently and effectively complete their tasks through  the '
                },
                {
                    tag: 'span',
                    props: { class: 'text-primary fw-5' },
                    text: 'Mi Office.'
                }
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'User Research'
        },
        {
            tag: 'div',
            props: { class: 'w-100 overflow-hidden d-flex grid mb-90' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'grid-half mr-5 mb-5' },
                    children: [
                        {
                            tag: 'div',
                            props: { class: 'flex-1 mr-5 overflow-hidden background-gray p-36' },
                            children: [
                                {
                                    tag: 'img',
                                    props: { loading: "lazy", class: 'd-inline-block overflow-hidden img-icon mb-16', src: hashUrl('mioffice/icon1.png') },
                                },
                                {
                                    tag: 'h4',
                                    props: { class: 'mb-12 fs-16 fw-7 p-0' },
                                    text: 'INTERVIEW'
                                },
                                {
                                    tag: 'p',
                                    props: { class: 'text-light fs-14 p-0' },
                                    text: 'Every program we will interview at least 10 General Employees and 5 High-level Employees'
                                }
                            ]
                        },
                        {
                            tag: 'div',
                            props: { class: 'flex-1 overflow-hidden background-gray p-36' },
                            children: [
                                {
                                    tag: 'img',
                                    props: { loading: "lazy", class: 'd-inline-block overflow-hidden img-icon mb-16', src: hashUrl('mioffice/icon2.png') },
                                },
                                {
                                    tag: 'h4',
                                    props: { class: 'mb-12 fs-16 fw-7 p-0' },
                                    text: 'USABILITY TESTING'
                                },
                                {
                                    tag: 'p',
                                    props: { class: 'text-light fs-14 p-0' },
                                    text: 'We recorded how real users interact with the app, and gather the direct user feedback'
                                }
                            ]
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'grid-half mb-5' },
                    children: [
                        {
                            tag: 'div',
                            props: { class: 'flex-1 mr-5 overflow-hidden background-gray p-36' },
                            children: [
                                {
                                    tag: 'img',
                                    props: { loading: "lazy", class: 'd-inline-block overflow-hidden img-icon mb-16', src: hashUrl('mioffice/icon3.png') },
                                },
                                {
                                    tag: 'h4',
                                    props: { class: 'mb-12 fs-16 fw-7 p-0' },
                                    text: 'A/B TESTING'
                                },
                                {
                                    tag: 'p',
                                    props: { class: 'text-light fs-14 p-0' },
                                    text: 'Compare different interaction solutions'
                                }
                            ]
                        },
                        {
                            tag: 'div',
                            props: { class: 'flex-1 overflow-hidden background-gray p-36' },
                            children: [
                                {
                                    tag: 'img',
                                    props: { loading: "lazy", class: 'd-inline-block overflow-hidden img-icon mb-16', src: hashUrl('mioffice/icon4.png') },
                                },
                                {
                                    tag: 'h4',
                                    props: { class: 'mb-12 fs-16 fw-7 p-0' },
                                    text: 'SURVEY'
                                },
                                {
                                    tag: 'p',
                                    props: { class: 'text-light fs-14 p-0' },
                                    text: 'Distribute the survey company-wide to gather quantitative and qualitative feedback'
                                }
                            ]
                        }
                    ]
                },
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Competitor analysis'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Our team chose the two most popular office management platforms in China, DingTalk and WeCom, along with the native Lark smart office platform, for competitive analysis. we thoroughly explored each platform and interviewed employees from other companies to gather their feedback and experience ratings.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mioffice/img5.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'My Deliverables'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'In the Mi Office project, each designer on our team was responsible for multiple mini-apps. Following a unified design system, we developed design strategies, wireframes, and high-fidelity prototypes from scratch. As the DRI(Directly Responsible Individual) for several key projects, I delivered over 10 mini-apps across PC and mobile platforms and designed promotional posters for each.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mioffice/img18.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6 text-primary' },
            text: 'Mi Approval'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Mi approval is an efficient and reliable process approval management tool that covers hundreds of business scenarios.It aims to integrate approval workflows of all categories and break down barriers between different teams. With one-stop approval service achieved, It offers an easy and efficient experience for users.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('mioffice/img6.png') },
        },
        {
            tag: 'slide-box',
            // events: { mouseleave: 'resetSlide' },
            props: { class: 'pb-8 phone-box mb-90', slideName: 'approval', images: [hashUrl('mioffice/img9.png'), hashUrl('mioffice/img10.png'), hashUrl('mioffice/img11.png'), hashUrl('mioffice/img12.png'), hashUrl('mioffice/img13.png')] }
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Case study'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('mioffice/img19.png') },
        },
        {
            tag: 'img-viewer',
            props: {
                galleryID: 'mioffice', class: 'mb-90', images: [
                    {
                        largeURL: hashUrl('mioffice/l1.png'),
                        thumbnailURL: hashUrl('mioffice/img20.png'),
                        width: 2000,
                        height: 4722,
                    },
                    {
                        largeURL: hashUrl('mioffice/l2.png'),
                        thumbnailURL: hashUrl('mioffice/img21.png'),
                        width: 2000,
                        height: 4783,
                    },
                    {
                        largeURL: hashUrl('mioffice/l3.png'),
                        thumbnailURL: hashUrl('mioffice/img22.png'),
                        width: 2000,
                        height: 6693,
                    },
                    {
                        largeURL: hashUrl('mioffice/l4.png'),
                        thumbnailURL: hashUrl('mioffice/img23.png'),
                        width: 2000,
                        height: 3078,
                    },
                ],
            }
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Key Features to Improve UX and Efficiency'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('mioffice/img24.png') },
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('mioffice/img25.png') },
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('mioffice/img26.png') },
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mioffice/img27.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6 text-primary' },
            text: 'Mi Reimbursement'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Mi Reimbursement is a tool for managing employee reimbursement processes. With Mi Reimbursement, employees can easily manage daily reimbursements and various financial tasks related to their work.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mioffice/img14.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6 text-primary' },
            text: 'Invoice Management'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Invoice Management is a tool that helps employees effortlessly manage various work-related invoices, it supports uploading, transferring, and receiving invoices.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mioffice/img15.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6 text-primary' },
            text: 'Other Mini-apps'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Besides the above, I also led the design of several useful mini-apps, including an electronic work badge app for linking physical badges to NFC-enabled devices, Xiaomi Taxi for employee transportation without personal payment, a business card request app for standardized company cards, and an office supply request app for daily needs. These tools provide a convenient and smart workplace experience for over 35,000 Xiaomi employees.'
        },
        {
            tag: 'auto-video',
            props: { class: 'w-100 mb-36', src: hashUrl('mioffice/video1.mp4') },
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mioffice/img16.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Reflection'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-48 text-wrap mb-36' },
            text: 'Through my work on Mi Office, I gained a deeper understanding of B2B product design. Beyond enhancing my research and design skills, I developed valuable communication skills. Engaging with senior employees taught me to approach office system design from a higher-level perspective, focusing on core needs and strategic priorities.'
        },
        {
            tag: 'div',
            props: { class: 'd-flex overflow-hidden responsive-flex mb-90' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1 mr-5' },
                    children: [
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-14 fw-5 mb-12 text-40percent' },
                            text: 'INSIGHT01'
                        },
                        {
                            tag: 'h4',
                            props: { class: 'p-0 fs-18 mb-16' },
                            text: 'Understanding User Needs and Research'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-14 mb-0 text-light fw-1' },
                            text: `Products of a similar type also require precise targeting when selecting user samples; a one-size-fits-all approach won't work effectively.`
                        }
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1 mr-5' },
                    children: [
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-14 fw-5 mb-12 text-40percent' },
                            text: 'INSIGHT02'
                        },
                        {
                            tag: 'h4',
                            props: { class: 'p-0 fs-18 mb-16' },
                            text: 'Information Architecture and Interaction Design'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-14 mb-0 text-light fw-1' },
                            text: `When designing complex application approval interfaces, it's essential to minimize distracting information on each page while providing “shortcuts” that streamline users' navigation.`
                        }
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1' },
                    children: [
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-14 fw-5 mb-12 text-40percent' },
                            text: 'INSIGHT03'
                        },
                        {
                            tag: 'h4',
                            props: { class: 'p-0 fs-18 mb-16' },
                            text: 'Iterative Optimization and User Feedback'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-14 mb-0 text-light fw-1' },
                            text: `Don’t blindly cater to user requests or iterate for the sake of it; each change should align with user needs and business goals to add real value.`
                        }
                    ]
                }
            ]
        },
    ],
    "miTravel": [
        {
            tag: 'h1',
            props: { class: 'project-name fs-48' },
            text: 'Mi Travel'
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Company'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'Xiao Mi'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Role'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'Design lead & UX/UI designer'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap mb-120' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Duration'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: '2021-2022 (2 Iterations)'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Deliverable'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: 'Product Design Strategy, 0-1 UX/UI design'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'What is Mi Travel?'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Xiaomi Travel is a business travel management platform . It offers a comprehensive range of features covering travel approvals, flight and train ticket bookings, hotel reservations, ride-hailing, dining, and more. The platform is designed to enhance travel efficiency and improve employee satisfaction.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mitravel/img1.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Who are the target users?'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'The platform is designed for all employees within the company，We have summarized the three most typical types of users as follows.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mitravel/img2.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Business Goal'
        },
        {
            tag: 'div',
            props: { class: 'w-100 mb-90 overflow-hidden clear-float' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('mitravel/goal1.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('mitravel/goal2.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('mitravel/goal3.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left mr-0', src: hashUrl('mitravel/goal4.png') },
                },
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Strategy'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'We first mapped the traditional travel and reimbursement processes. Interviews with employees and executives helped us understand user pain points, leading to a more efficient and user-friendly workflow.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('mitravel/img5.png') },
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('mitravel/img6.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'How we make it possible?'
        },
        {
            tag: 'div',
            props: { class: 'd-flex overflow-hidden grid' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1 mr-20 mb-20' },
                    children: [
                        {
                            tag: 'h4',
                            props: { class: 'fs-18 mb-16 text-wrap' },
                            text: '1. Comprehensive service integration'
                        },
                        {
                            tag: 'p',
                            props: { class: 'fs-16 mb-12 text-light  text-wrap' },
                            text: 'To meet our business goals, we partnered with China’s largest travel providers, integrating services like trains, flights, hotels, car rentals, and dining into our travel management app.'
                        },
                        {
                            tag: 'p',
                            props: { class: 'fs-16 mb-0 text-light text-wrap' },
                            children: [
                                {
                                    tag: 'span',
                                    text: 'With these integrated services, employees can book hotels, tickets, and meals without out-of-pocket expenses,'
                                },
                                {
                                    tag: 'span',
                                    props: { class: 'text-primary' },
                                    text: 'removing the need for reimbursement.'
                                }
                            ]
                        }
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'card mb-20 flex-1 ph-36 pv-48 align-items-center justify-content-center' },
                    children: [
                        {
                            tag: 'img',
                            props: { loading: "lazy", class: 'w-75 mb-36 overflow-hidden', src: hashUrl('mitravel/img7.png') },
                        },
                        {
                            tag: 'span',
                            text: 'Our Partners'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'div',
            props: { class: 'card pv-48 ph-36 w-100 overflow-hidden mb-90' },
            children: [
                {
                    tag: 'h4',
                    props: { class: 'fs-18 mb-16' },
                    text: '2. User Research'
                },
                {
                    tag: 'p',
                    props: { class: 'fs-16 fw-3 mb-36 text-light text-wrap' },
                    text: 'We began by conducting in-depth interviews with representative users. After creating the wireframes, we invited our users—our colleagues—to participate in usability testing. Following a round of beta testing, we distributed a survey to gather feedback for improvements, which led to the finalization of the design.'
                },
                {
                    tag: 'h4',
                    props: { class: 'fs-18 mb-36' },
                    text: '3. Competitor Research'
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'w-100 d-block overflow-hidden', src: hashUrl('mitravel/img8.png') },
                },
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'My Deliverables'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Due to the tight project delivery timeline, the MiTravel project was divided into two phases. In the first phase, I developed a mobile mini app that implemented the basic functionalities outlined in the initial planning. In the second phase, based on user feedback collected from version 1.0 and the refinement of the departmental design system guidelines, I conducted a comprehensive visual and experiential optimization of the version 1.0 app and designed a PC version.'
        },
        {
            tag: 'div',
            props: { class: 'card p-8 w-100 overflow-hidden mb-36' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'w-100 d-block overflow-hidden', src: hashUrl('mitravel/img9.png') },
                }
            ]
        },
        {
            tag: 'div',
            props: { class: 'd-flex w-100 overflow-hidden grid mb-0' },
            children: [
                {
                    tag: 'auto-video',
                    props: { class: 'flex-1 d-block border-card mb-20 mr-20 overflow-hidden border-none background-black', src: hashUrl('mitravel/video1.mp4') },
                },
                {
                    tag: 'div',
                    props: { class: 'card flex-1 mb-20' },
                    children: [
                        {
                            tag: 'h4',
                            props: { class: 'pt-48 ph-36 fs-18 mb-16' },
                            text: 'Travel Request Form'
                        },
                        {
                            tag: 'p',
                            props: { class: 'ph-36 fs-16 fw-3 mb-0 pb-48 text-light' },
                            text: 'The travel request form is designed for simplicity, with minimal input required and key fields auto-filled, making the application process quick and efficient.'
                        }
                    ]
                }
            ]
        },
        {
            tag: 'div',
            props: { class: 'd-flex w-100 overflow-hidden grid mb-36' },
            children: [

                {
                    tag: 'div',
                    props: { class: 'flex-1 card mr-20 mb-20' },
                    children: [
                        {
                            tag: 'h4',
                            props: { class: 'text-primary fs-18 ph-36 pt-48 mb-36' },
                            text: 'China Railway x Xiaomi'
                        },
                        {
                            tag: 'h4',
                            props: { class: 'fs-18 mb-16 ph-36' },
                            text: 'Train Ticket Booking Platform'
                        },
                        {
                            tag: 'p',
                            props: { class: 'ph-36 fs-16 mb-0 pb-48 text-light' },
                            text: 'We partnered with China Railway to design a travel-focused ticket booking platform. Compared to standard platforms, it prioritizes booking efficiency, supports group ticket purchases, links to travel applications, and offers 24/7 cancellation and rebooking services.'
                        }
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-1 mb-5 d-flex flex-direction-column border-card border-none justify-content-end background-black' },
                    children: [
                        {
                            tag: 'img',
                            props: { loading: "lazy", class: 'overflow-hidden w-100 mb-0', src: hashUrl('mitravel/img19.png') },
                        },
                    ]
                },

            ]
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img card p-8 w-100 overflow-hidden mb-36', src: hashUrl('mitravel/img11.png') },
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('mitravel/img12.png') },
        },
        {
            tag: 'img-viewer',
            props: {
                galleryID: 'mitravle', class: 'mb-90', images: [
                    {
                        largeURL: hashUrl('mitravel/l1.png'),
                        thumbnailURL: hashUrl('mitravel/img13.png'),
                        width: 2160,
                        height: 1532,
                    },
                    {
                        largeURL: hashUrl('mitravel/l2.png'),
                        thumbnailURL: hashUrl('mitravel/img14.png'),
                        width: 2160,
                        height: 1532,
                    },
                    {
                        largeURL: hashUrl('mitravel/l3.png'),
                        thumbnailURL: hashUrl('mitravel/img15.png'),
                        width: 2160,
                        height: 1532,
                    },
                    {
                        largeURL: hashUrl('mitravel/l4.png'),
                        thumbnailURL: hashUrl('mitravel/img15a.png'),
                        width: 2160,
                        height: 1532,
                    },
                ],
            }
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Project Results and Key Metrics'
        },
        {
            tag: 'div',
            props: { class: 'w-100 d-flex overflow-hidden mb-90' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'overflow-hidden mr-20 flex-1', src: hashUrl('mitravel/img16.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'overflow-hidden mr-20 flex-1', src: hashUrl('mitravel/img17.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'overflow-hidden mr-0 flex-1', src: hashUrl('mitravel/img18.png') },
                },
            ]
        }
    ],
    "afterSale": [
        {
            tag: 'h1',
            props: { class: 'project-name fs-48' },
            text: 'Xiaomi After-sale APP'
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Company'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'Xiao Mi'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Role'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'Design lead & UX/UI designer'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap mb-120' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Duration'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: '2019-2021 (3 Iterations)'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Deliverable'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: 'Product Design Strategy, UX&UI design'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'What is After-sale APP?'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: `The Xiaomi After-Sales App primarily helps engineers manage after-sales processes for efficient service delivery, while allowing managers to monitor and analyze after-sales data for performance insights.`
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('aftersale/img1.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Who are the target users?'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: `To better understand the target users and their needs, I conducted a survey with Xiaomi’s after-sales team.`
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-5 mb-36 text-wrap text-mi' },
            text: `Key Findings`
        },
        {
            tag: 'ul',
            props: { class: 'fs-20 text-wrap mb-36 fw-3' },
            children: [
                {
                    tag: 'li',
                    text: 'A significant number of after-sales engineers are third-party labor.'
                },
                {
                    tag: 'li',
                    text: 'The majority of users are male front-line engineers, followed by supervisors who oversee their work.'
                },
                {
                    tag: 'li',
                    text: `Most users are in their early 30s, with most holding high school or associate degrees.`
                }
            ]
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('aftersale/img2.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'The conflict between engineers and the company'
        },
        {
            tag: 'div',
            props: { class: 'w-100 d-flex overflow-hidden mb-90' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1 text-center' },
                    children: [
                        {
                            tag: 'h4',
                            props: { class: 'p-0 fs-18 mb-16' },
                            text: `Resists Monitoring`
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-16 mb-0 text-light' },
                            text: `In one-on-one interviews with our target users, their top request was to avoid having an app to regulate their after-sales service. Over 55% of engineers believe that using the app slows down their after-sales process.`
                        }
                    ]
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'vs', src: hashUrl('aftersale/vs.png') },

                },
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1 text-center' },
                    children: [
                        {
                            tag: 'h4',
                            props: { class: 'p-0 fs-18 mb-16' },
                            text: `Ensuring Service Quality`
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-16 mb-0 text-light' },
                            text: `It’s crucial to effectively manage engineers through the after-sales app, safeguarding the company’s interests, ensuring the quality of after-sales services, and securing positive feedback from customers.`
                        }
                    ]
                },
            ],
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Version 1.0 Heuristic Evaluation'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: `The 1.0 version was created without UX design input and was fully developed by our engineers. Given this, I invited two designers from our team to conduct a heuristic evaluation.`
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-5 mb-36 text-wrap text-mi' },
            text: `Key insights`
        },
        {
            tag: 'div',
            props: { class: 'w-100 overflow-hidden d-flex grid mb-90' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'grid-half mr-5 mb-5' },
                    children: [
                        {
                            tag: 'div',
                            props: { class: 'flex-1 mr-5 overflow-hidden background-gray p-36' },
                            children: [
                                {
                                    tag: 'h4',
                                    props: { class: 'mb-12 fs-16 fw-7 p-0' },
                                    text: `Lack of User-Centered Design`
                                },
                                {
                                    tag: 'p',
                                    props: { class: 'text-light fs-14 p-0' },
                                    text: `Confusing structure and cluttered layout make the site hard to use.`
                                }
                            ]
                        },
                        {
                            tag: 'div',
                            props: { class: 'flex-1 overflow-hidden background-gray p-36' },
                            children: [
                                {
                                    tag: 'h4',
                                    props: { class: 'mb-12 fs-16 fw-7 p-0' },
                                    text: `Inconsistent Design`
                                },
                                {
                                    tag: 'p',
                                    props: { class: 'text-light fs-14 p-0' },
                                    text: `Visual and interaction styles lack consistency, impacting brand cohesion.`
                                }
                            ]
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'grid-half mb-5' },
                    children: [
                        {
                            tag: 'div',
                            props: { class: 'flex-1 mr-5 overflow-hidden background-gray p-36' },
                            children: [
                                {
                                    tag: 'h4',
                                    props: { class: 'mb-12 fs-16 fw-7 p-0' },
                                    text: `High Cognitive Load`
                                },
                                {
                                    tag: 'p',
                                    props: { class: 'text-light fs-14 p-0' },
                                    text: `Tasks require too many steps, reducing efficiency.`
                                }
                            ]
                        },
                        {
                            tag: 'div',
                            props: { class: 'flex-1 overflow-hidden background-gray p-36' },
                            children: [
                                {
                                    tag: 'h4',
                                    props: { class: 'mb-12 fs-16 fw-7 p-0' },
                                    text: `Slow Performance`
                                },
                                {
                                    tag: 'p',
                                    props: { class: 'text-light fs-14 p-0' },
                                    text: `Long load times and poor responsiveness frustrate users.`
                                }
                            ]
                        }
                    ]
                },
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: `Emerging needs from Stakeholders`
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-90 text-wrap' },
            text: `Reconstruction of the after-sales app’s work order functionality to enable the fast processing of work orders through the app, with features such as reassignment, cancellation, and creation of new orders.`
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Business Goal of the new version'
        },
        {
            tag: 'div',
            props: { class: 'w-100 mb-90 overflow-hidden clear-float' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('aftersale/img3.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('aftersale/img4.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left', src: hashUrl('aftersale/img5.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'quartor float-left mr-0', src: hashUrl('aftersale/img6.png') },
                },
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Key Steps in Shaping Design Solution'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: `The main difference with B2B products is that users rely on them daily to meet work goals, not for enjoyment. This means we need to work closely with users to simplify workflows and boost efficiency.`
        },
        {
            tag: 'div',
            props: { class: 'w-100 overflow-hidden d-flex grid mb-20' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'd-inline-block flex-1 mr-20 overflow-hidden', src: hashUrl('aftersale/img7.png') },
                },
                {
                    tag: 'div',
                    props: { class: 'card flex-1' },
                    children: [
                        {
                            tag: 'h5',
                            props: { class: 'text-mi pt-48 ph-36 fs-18 mb-16' },
                            text: '1. Gain insights from the successful'
                        },
                        {
                            tag: 'p',
                            props: { class: 'ph-36 fs-16 text-light mb-0 pb-48' },
                            text: `As a young brand, Xiaomi has opportunities to improve in after-sales service. We chose Midea’s after-sales app as our main competitor and invited Midea’s After-Sales Product Manager to share insights on their successful app with our team.`
                        },
                    ]
                },
            ]
        },
        {
            tag: 'div',
            props: { class: 'w-100 overflow-hidden d-flex grid mb-20' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'd-inline-block flex-1 mr-20 overflow-hidden', src: hashUrl('aftersale/img8.png') },
                },
                {
                    tag: 'div',
                    props: { class: 'card flex-1' },
                    children: [
                        {
                            tag: 'h5',
                            props: { class: 'text-mi pt-48 ph-36 fs-18 mb-16' },
                            text: `2. Firsthand Experience to Identify User Pain Points`
                        },
                        {
                            tag: 'p',
                            props: { class: 'ph-36 fs-16 text-light mb-0 pb-48' },
                            text: `As a UX leader of this project  I accompanied Xiaomi’s installation engineers on home visits to experience the entire after-sales process firsthand, allowing me to better identify and address user pain points`
                        },
                    ]
                },
            ]
        },
        {
            tag: 'div',
            props: { class: 'w-100 overflow-hidden d-flex grid mb-48' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'd-inline-block flex-1 mr-20 overflow-hidden', src: hashUrl('aftersale/img9.png') },
                },
                {
                    tag: 'div',
                    props: { class: 'card flex-1' },
                    children: [
                        {
                            tag: 'h5',
                            props: { class: 'text-mi pt-48 ph-36 fs-18 mb-16' },
                            text: `3. Collaborative Design Sprint for Rapid Solutions`
                        },
                        {
                            tag: 'p',
                            props: { class: 'ph-36 fs-16 text-light mb-0 pb-48' },
                            text: `We organized a Design Sprint, inviting designers, PMs, and developers to join stakeholders in a single meeting room. In a short time, we identified key issues, brainstormed ideas, and finalized the core design plan for the after-sales app.`
                        },
                    ]
                },
            ]
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-5 mb-36 text-wrap' },
            text: `User flow & Wireframe Development`
        },
        {
            tag: 'swipe-box',
            props: { class: "mb-90", images: [hashUrl('aftersale/img10.png'), hashUrl('aftersale/img11.png')] },

        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: `Deliveries of the main pages`
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: `The project went through 3 major iterations, the final design not only digitized the entire after-sales management process for engineers but also added several useful modules, such as an after-sales knowledge base, materials management, and performance data tracking for engineers.`
        },
        {
            tag: 'auto-video',
            props: { class: 'w-100 mb-36', src: hashUrl('aftersale/video1.mp4') },
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('aftersale/img12.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: `Project Results and Key Metrics`
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-90 text-wrap' },
            children: [
                { 
                    tag: 'span',
                    text: `The after-sales app, launched in May 2020 and tested by 699 engineers, was rolled out nationwide in July 2020 after optimization. It significantly improved order processing efficiency and customer satisfaction, which reached `
                },
                {
                    tag: 'span',
                    props: { class: 'text-mi' },
                    text: `98.38%`
                },
                {
                    tag: `span`,
                    text: ` in 2021, a 1.06% increase from the previous year, marking the project’s success.`
                }
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Reflection'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap mb-90' },
            text: `This project taught me the value of collaborating with cross-functional teams. We set up a group chat where I not only contributed as a designer but also gathered direct user feedback, gaining a deeper understanding of the after-sales process. It also enhanced my perspective on B2B product design, highlighting the need to balance user experience with business requirements.`
        },
    ],
    "hiui": [
        {
            tag: 'h1',
            props: { class: 'project-name fs-48' },
            text: 'HIUI Design System'
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Company'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'Xiao Mi'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Role'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'UX/UI designer'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap mb-120' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Duration'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: '2021-2022'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Deliverable'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: 'Contribute to defining design principles and guidelines, and create design assets for mobile and PC'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'What is HIUI?'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'HIUI is an enterprise-level design system launched by Xiaomi Group’s Information Technology Department. Currently focused on enterprise-level backend development scenarios, HIUI embraces the design values of “efficiency, friendliness, and openness,” aiming to create a seamless and enjoyable work experience.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('hiui/img1.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Why we do this project?'
        },
        {
            tag: 'div',
            props: { class: 'd-flex overflow-hidden responsive-flex mb-90' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1 mr-5' },
                    children: [
                        {
                            tag: 'h4',
                            props: { class: 'p-0 fs-18 mb-16' },
                            text: '01 Enhancing Efficiency'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-16 fw-3 mb-12 text-light' },
                            text: 'Xiaomi’s backend systems need unified design and tech stacks for efficient business support.'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-16 mb-0 text-light' },
                            text: 'Reusable modules lower learning curves, shorten development time, and boost efficiency.'
                        }
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1 mr-5' },
                    children: [
                        {
                            tag: 'h4',
                            props: { class: 'p-0 fs-18 mb-16' },
                            text: '02 Unified Experience'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-16 mb-12 text-light' },
                            text: 'Multiple product lines need a unified design language and interaction standards to ensure consistent user experience'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-16 mb-0 text-light' },
                            text: 'Creates a cohesive brand identity, enhancing the overall brand perception.'
                        }
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'card ph-36 pv-48 flex-1' },
                    children: [
                        {
                            tag: 'h4',
                            props: { class: 'p-0 fs-18 mb-16' },
                            text: '03 Promoting Collaboration'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-16 mb-12 text-light' },
                            text: 'A unified design system can reduces ineffective communication between teams.'
                        },
                        {
                            tag: 'p',
                            props: { class: 'p-0 fs-16 mb-0 text-light' },
                            text: 'Building a design system fosters collaboration and communication between different roles within the company.'
                        }
                    ]
                }
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'The User'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('hiui/img12.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'The Competitor'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'We first researched the most popular design systems currently on the market to understand their design styles and philosophies.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('hiui/img2.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Design Strategy'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 fw-7' },
            text: '1. Define the Design System Blueprint'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('hiui/img3.png') },
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 fw-7' },
            text: '2. Define Design Language & Principle'
        },
        {
            tag: 'p',
            props: { class: 'w-100 text-center p-0 mb-36' },
            children: [
                {
                    tag: 'span',
                    props: {},
                    text: 'Key Characteristics of the '
                },
                {
                    tag: 'span',
                    props: { class: 'text-mi fw-7' },
                    text: 'Xiaomi'
                },
            ]
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-20', src: hashUrl('hiui/img4.png') },
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('hiui/img5.png') },
        },
        {
            tag: 'p',
            props: { class: 'fs-20 mb-36 fw-7' },
            text: '3. Build Design Assets'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'We built the entire HIUI design assets based on the principles and methodology of Atomic Design.'
        },
        {
            tag: 'div',
            props: { class: 'd-flex overflow-hidden mb-36 responsive-flex' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'responsive-img info-img mid mb-20', src: hashUrl('hiui/img7.png') },

                },
                {
                    tag: 'div',
                    props: { class: 'card ml-5 p-8 flex-1 mb-20' },
                    children: [
                        {
                            tag: 'p',
                            props: { class: 'fw-7 fs-16 mb-20 p-0' },
                            text: 'Click to Experience'
                        },
                        {
                            tag: 'a',
                            props: { class: 'text-primary break-all', href: 'https://xiaomi.github.io/hiui/', target: "_blank" },
                            text: 'https://xiaomi.github.io/hiui/'
                        }
                    ]
                }
            ]
        },
        {
            tag: 'img-viewer',
            props: {
                galleryID: 'hiui', class: 'mb-90', images: [
                    {
                        largeURL: hashUrl('hiui/l1.png'),
                        thumbnailURL: hashUrl('hiui/img8.png'),
                        width: 1920,
                        height: 1080,
                    },
                    {
                        largeURL: hashUrl('hiui/l2.png'),
                        thumbnailURL: hashUrl('hiui/img9.png'),
                        width: 1920,
                        height: 1080,
                    },
                    {
                        largeURL: hashUrl('hiui/l3.png'),
                        thumbnailURL: hashUrl('hiui/img10.png'),
                        width: 1920,
                        height: 1080,
                    },
                    {
                        largeURL: hashUrl('hiui/l4.png'),
                        thumbnailURL: hashUrl('hiui/img11.png'),
                        width: 1920,
                        height: 1080,
                    },
                ],
            }
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Project Status'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'The HIUI Design System 4.0 has been implemented in over 50 projects, significantly improving both development efficiency and quality.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('hiui/img6.png') },

        },
    ],
    "miCost": [
        {
            tag: 'h1',
            props: { class: 'project-name fs-48' },
            text: 'Mi Cost Estimate'
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Company'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'Xiao Mi'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Role'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-48' },
                            text: 'Design lead & UX/UI designer'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'div',
            props: { class: 'd-flex text-wrap mb-120' },
            children: [
                {
                    tag: 'div',
                    props: { class: 'mr-32 flex-1' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'Duration'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: 'Aug,2022 - Dec,2022'
                        },
                    ]
                },
                {
                    tag: 'div',
                    props: { class: 'flex-2' },
                    children: [
                        {
                            tag: 'h3',
                            props: { class: 'mt-0 mb-text fs-24 lh-40' },
                            text: 'My Deliverable'
                        },
                        {
                            tag: 'p',
                            props: { class: 'text-light fs-20 lh-36 mt-0 mb-0' },
                            text: 'UX & UI design of PC platform, Graphic Design'
                        },
                    ]
                }
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'what is Mi Cost Estimate?'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'MI Cost Estimate is a platform for estimating product costs made by Xiaomi. I participated in the development of version 1.0, which focuses on cost estimation for Xiaomi automobiles. The system enables responsible personnel to estimate the costs of various car components and quickly generate cost estimation reports.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('micost/img1.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Background'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            children: [
                {
                    tag: 'span',
                    text: 'Prior to this project, my focus was on smart office design, with little experience in the automotive industry. In March 2021, when Xiaomi entered the automobile manufacturing sector, our IT department needed to support the automotive division. I then took on two key projects: the Automotive Budget Management Platform and the ',
                },
                {
                    tag: 'span',
                    props: { class: 'text-primary fw-7' },
                    text: 'Cost Estimation Platform.',
                }
            ]
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('micost/img2.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Version 1.0 Core Need'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-90 text-wrap' },
            text: 'To provide a fast and accurate way to estimate the cost of vehicles and their components, supporting business decision-making.'
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Who are the target users?'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'The Program Manager and Engineer of Xiaomi Automotive Team, most of them come from traditional automotive companies like BMW, Mercedes-Benz, and others.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('micost/img3.png') },
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Strategy'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-7 mb-36' },
            text: '1. Competitor Analysis'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'Since the project involves sensitive information like product costs and labor costs, it’s a challenge for us to find similar products. From the limited competitor product images available, we can see that in traditional industries, cost estimation systems often resemble Excel spreadsheets. These interfaces tend to be cluttered, with small text and charts, resulting in a poor user experience.'
        },
        {
            tag: 'div',
            props: { class: 'w-100 d-flex responsive-flex overflow-hidden mb-36' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'flex-1 card mr-20 overflow-hidden', src: hashUrl('micost/img4.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'flex-1 card mr-0 overflow-hidden', src: hashUrl('micost/img5.png') },
                },
            ]
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-7 mb-36' },
            text: '2. Define user flow'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('micost/img6.png') },
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-7 mb-36' },
            text: '3. Using standard component build the pages'
        },
        {
            tag: 'div',
            props: { class: 'w-100 clear-float overflow-hidden mb-36' },
            children: [
                // {
                //     tag: 'auto-video',
                //     props: { class: 'w-70 mr-20 float-left overflow-hidden responsive-img mb-20', src: hashUrl('micost/video1.mp4') },
                // },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'w-70 mr-20 float-left overflow-hidden responsive-img mb-20', src: hashUrl('micost/img12.png') },
                },
                {
                    tag: 'div',
                    props: { class: 'card pv-48 ph-36 mb-20' },
                    children: [
                        {
                            tag: 'img',
                            props: { loading: "lazy", class: 'mb-16 icon-mid', src: hashUrl('micost/icon1.png') },
                        },
                        {
                            tag: 'h5',
                            props: { class: 'fs-18 mb-16' },
                            text: 'Design Approach'
                        },
                        {
                            tag: 'p',
                            props: { class: 'fs-16 mb-0 text-light' },
                            children: [
                                {
                                    tag: 'span',
                                    text: 'Due to the tight project cycle, all draft designs were finalized using the '
                                },
                                {
                                    tag: 'router-link',
                                    props: { class: 'text-primary d-inline-block', to: '/project/hiui' },
                                    text: 'HIUI design system'
                                },
                                {
                                    tag: 'span',
                                    text: ' and components, which ensured a high quality design while significantly increasing efficiency and maintaining functional scalability.'
                                },
                            ]
                        }
                    ]
                }
            ]
        },
        {
            tag: 'img-viewer',
            props: {
                galleryID: 'micost', class: 'mb-90', images: [
                    {
                        largeURL: hashUrl('micost/l1.png'),
                        thumbnailURL: hashUrl('micost/img8.png'),
                        width: 2880,
                        height: 1844,
                    },
                    {
                        largeURL: hashUrl('micost/l2.png'),
                        thumbnailURL: hashUrl('micost/img9.png'),
                        width: 2880,
                        height: 1844,
                    },
                    {
                        largeURL: hashUrl('micost/l3.png'),
                        thumbnailURL: hashUrl('micost/img10.png'),
                        width: 2880,
                        height: 1844,
                    },
                    {
                        largeURL: hashUrl('micost/l4.png'),
                        thumbnailURL: hashUrl('micost/img11.png'),
                        width: 2880,
                        height: 1844,
                    },
                ],
            }
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Project Results'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: `The project is currently implemented in Xiaomi’s automotive R&D, with future versions planned to integrate cost estimation for all Xiaomi ecosystem products into the platform.`
        },
    ],
    "graphicDesign": [
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6 mt-48' },
            text: 'Game Design'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'In the Responsive Media course, I ventured into game design and development for the first time. I designed the user interface and game elements, and contributed to development using the Unity engine. After completing the initial version, our group conducted a usability study and compiled optimization plans. Ultimately, I completed my first game design project and received a good grade.'
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-36', src: hashUrl('design/img1.png') },
        },
        {
            tag: 'img-viewer',
            props: {
                galleryID: 'design', class: 'mb-36', images: [
                    {
                        largeURL: hashUrl('design/l1.png'),
                        thumbnailURL: hashUrl('design/img8.png'),
                        width: 3840,
                        height: 2160,
                    },
                    {
                        largeURL: hashUrl('design/l2.png'),
                        thumbnailURL: hashUrl('design/img9.png'),
                        width: 3840,
                        height: 2160,
                    },
                    {
                        largeURL: hashUrl('design/l3.png'),
                        thumbnailURL: hashUrl('design/img10.png'),
                        width: 3840,
                        height: 5118,
                    },
                    {
                        largeURL: hashUrl('design/l4.png'),
                        thumbnailURL: hashUrl('design/img11.png'),
                        width: 3840,
                        height: 3180,
                    },
                ],
            }
        },
        {
            tag: 'swipe-box',
            props: { class: "mb-72", images: [hashUrl('design/img2.png'), hashUrl('design/img3.png')] },
        },
        {
            tag: 'div',
            props: { class: 'mb-90 d-flex align-items-center justify-content-center' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'w-29 overflow-hidden', src: hashUrl('design/img6.png') },
                },
                {
                    tag: 'auto-video',
                    props: { class: 'flex-1 overflow-hidden phone-video', src: hashUrl('design/video1.mp4') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'w-29 overflow-hidden', src: hashUrl('design/img7.png') },
                },
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6 mt-48' },
            text: 'Motion Design'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'During my master’s, I also worked on several video projects, serving as the visual director and handling animation design.'
        },
        {
            tag: 'auto-video',
            props: { class: 'w-100 border-radius-primary mb-20 overflow-hidden', src: hashUrl('design/video2.mp4') },
        },
        {
            tag: 'div',
            props: { class: 'd-flex overflow-hidden responsive-flex mb-20' },
            children: [
                {
                    tag: 'auto-video',
                    props: { class: 'card flex-1 mr-20 border-radius-primary overflow-hidden', src: hashUrl('design/video3.mp4') },
                },
                {
                    tag: 'auto-video',
                    props: { class: 'card flex-1 border-radius-primary overflow-hidden', src: hashUrl('design/video4.mp4') },
                },
            ]
        },
        {
            tag: 'div',
            props: { class: 'd-flex overflow-hidden responsive-flex mb-90' },
            children: [
                {
                    tag: 'auto-video',
                    props: { class: 'card flex-1 mr-20 border-radius-primary overflow-hidden', src: hashUrl('design/video5.mp4') },
                },
                {
                    tag: 'auto-video',
                    props: { class: 'card flex-1 border-radius-primary overflow-hidden', src: hashUrl('design/video6.mp4') },
                },
            ]
        },
        {
            tag: 'h2',
            props: { class: 'fs-36 mb-36 fw-6' },
            text: 'Graphic Design'
        },
        {
            tag: 'p',
            props: { class: 'fs-20 fw-3 mb-36 text-wrap' },
            text: 'My passion for graphic design led me to create many works for my assignments, which helped me achieve high grades.'
        },
        {
            tag: 'div',
            props: { class: 'w-100 overflow-hidden d-flex mb-20' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'flex-1 mr-20 overflow-hidden', src: hashUrl('design/img12.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'flex-1 overflow-hidden', src: hashUrl('design/img13.png') },
                },
            ]
        },
        {
            tag: 'div',
            props: { class: 'w-100 overflow-hidden d-flex mb-20' },
            children: [
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'flex-1 mr-20 overflow-hidden', src: hashUrl('design/img14.png') },
                },
                {
                    tag: 'img',
                    props: { loading: "lazy", class: 'flex-1 overflow-hidden', src: hashUrl('design/img15.png') },
                },
            ]
        },
        {
            tag: 'img',
            props: { loading: "lazy", class: 'info-img w-100 overflow-hidden mb-90', src: hashUrl('design/img5.png') },
        },
    ],
}

export const PROJECT_INDICES = ["miOffice", "miTravel", "afterSale", "hiui", "miCost", "graphicDesign"]

export const SLIDE_INDICES = {
    "approval": 2,
    "design": 1
}