import { hashUrl } from "@/constant";
export default {
    components: {},
    data() {
        return {
        };
    },
    computed: {
        getImg() {
            return function (url) {
                if (url.startsWith('http')) {
                    return url;
                } else {
                    return require(`@/assets/${url}`);
                }

            };
        },
    },
    methods: {
        hashUrl
    }
};