import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue')
  },
  {
    path: '/project/:projectName',
    name: 'projectDetail',
    component: () => import('@/views/projectDetail.vue')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 如果有保存的滚动位置（比如浏览器后退操作）
      return savedPosition;
    } else {
      // 默认滚动到页面顶部
      return { x: 0, y: 0 };
    }
  }
})

export default router
